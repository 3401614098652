<template>
  <div class="qr-scanner">
    <div class="close-web-camera" @click="close">
      <i  class='bx bx-x'></i>
    </div>
    <div id="reader" class="reader"></div>
    <div class="qr-box"></div>
  </div>
</template>

<script>
import { Html5Qrcode } from "html5-qrcode";

export default {
  name: "QrScanner",
  data(){
    return {
      html5QrCode:null,
    }
  },
  mounted() {
    this.startScanner();
  },
  beforeDestroy() {
    if (this.html5QrCode && this.html5QrCode.isScanning) {
      this.html5QrCode.stop().catch(err => {
        console.error("Failed to stop the scanner:", err);
      });
    }
  },
  methods: {
    close(){
      //this.html5QrCode?.stop()
      console.log('CLOSE1')
      this.$emit('close')
    },
    startScanner() {
      const html5QrCode = new Html5Qrcode("reader");

      html5QrCode.start(
          { facingMode: "environment" },
          {
            fps: 10,
            qrbox: { width: 250, height: 250 }
          },
          (decodedText, decodedResult) => {
            let audio = document.getElementById('scannerAudio');

            audio.play().catch(e => {
              console.log(e)
            })
            this.$emit('scanned', decodedText)
          },
          (errorMessage) => {
          }
      ).catch(err => {
      });

      this.html5QrCode = html5QrCode;
    },
  }
};
</script>

<style>

.qr-scanner video {
  object-fit: cover !important;
  min-height: 100vh !important;
}
</style>
<style scoped>
.qr-scanner {
  position: relative;
  width: 100vw;
  height: 100vh;
}

.reader {
  width: 100%;
  height: 100%;
}

.qr-box {
  position: absolute;
  top: 50%;
  left: 50%;
  width: 250px;
  height: 250px;
  border: 5px solid #5e79ff;
  transform: translate(-50%, -50%);
  pointer-events: none;
}
.close-web-camera{
  position: absolute;
  font-size: 34px;
  left: 30px;
  top: 15px;
  text-align: center;
  padding-top: 5px;
  width: 50px;
  height: 50px;
  border-radius: 100px;
  background-color: #fdfdfd;
  z-index: 9999;
}
</style>
